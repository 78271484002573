import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Card } from 'react-bootstrap';
import { Loading } from './Loading';
import { LoaderButton } from './LoaderButton';
import Thankyou from './Thankyou';
import { sendFeedback, verifyGoogleReCaptcha } from '../assets/scripts/feedback';
import { useToggle, useRouter } from '../assets/scripts/hooksLib';
import { onError } from '../assets/scripts/errorLib';
// import '../assets/styles/app.scss';
// import '../assets/styles/index.scss';
// import '../assets/styles/main.scss';
import '../assets/styles/feedback.scss';

/**
 * Global Stitchz.net footer
 * @returns {HTMLElement} html of the global footer
 */
export default function ContactUs() {
    const router = useRouter();
    const [feedback, setFeedback] = useState({});
    const [isLoading, setIsLoading] = useToggle(false); // eslint-disable-line no-unused-vars
    const [failure, setFailure] = useState();
    const [isSuccessful, setIsSuccessful] = useState(false);

    /**
     * @typedef {{
     * onkeyup: number
     * }} KeyEvent
     */

    // check if this page was loaded from the contact us form in the footer
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            if (router.query.thankyou) {
                setIsSuccessful(true);
            }
        }

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [])

    // load Google reCAPTCHA
    useEffect(() => {
        // dynamically add the Google reCAPTCHA JS to the page
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?render=6LeZo20aAAAAAPJsjooWFn2NGw0ipnVmaGDrUzQA';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        // clean up the Stripe JS script when we leave
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    /**
     * Returns true if the feedback object has been completely filled out
     * @returns {boolean} true or false
     */
    const validate = () => {
        return feedback &&
            feedback.FullName && feedback.FullName.length > 3 &&
            feedback.Email && feedback.Email.length > 3 &&
            feedback.Enquiry && feedback.Enquiry.length > 3;
    };

    /**
     * Track each key press to update the feedback state
     * @param {KeyEvent} e key press event
     * @returns {void}
     */
    const onChange = (e) => {
        const field = e.target.id,
            val = e.target.value;

        setFeedback(feedback => {
            return { ...feedback, [field]: val }
        });
    };

    /**
     * Verify the given reCAPTCHA is valid
     * @param {string} token recaptcha token to verify
     * @returns {JSON} json object of response
     */
    const verifyCaptcha = async (token) => {
        const { status, message, body } = await verifyGoogleReCaptcha({ code: token });

        if (status !== 'OK') {
            onError(message);
        } else {
            return body;
        }
    };

    /**
     * Sends the Q&A and/or feedback
     * @param {MouseEvent} e form submit
     * @returns {void}
     */
    const onSubmit = async (e) => {
        if (e)
            e.preventDefault();

        setIsLoading(true);

        window.grecaptcha.ready(async () => {
            window.grecaptcha.execute('6LeZo20aAAAAAPJsjooWFn2NGw0ipnVmaGDrUzQA', { action: 'submit' })
                .then(async token => {
                    try {
                        const gReResponse = JSON.parse(await verifyCaptcha(token));

                        // console.log(gReResponse);

                        if (!gReResponse.success) {
                            const errors = gReResponse['error-codes'].join();

                            setFailure(errors);
                        } else {

                            try {
                                const { status, message } = await sendFeedback(feedback)
                                    .catch(err => {
                                        return {
                                            status: 'failed',
                                            message: err.response && err.response.data ? err.response.data.message : err.message
                                        };
                                    });

                                if (status !== 'OK') {
                                    onError(message);
                                } else {
                                    setIsSuccessful(true);
                                }
                            } catch (err) {
                                onError(err);
                            } finally {
                                setIsLoading(false);
                            }
                        }
                    } catch (err) {
                        console.error(err);
                        setFailure('There was an error gathering your feedback.');
                    }

                    return false;
                });
        });
    };

    return (
        isLoading ? <Loading /> :
            isSuccessful ? <Thankyou /> :
                <>
                    <div className="section-subtext">Got a question? Need more info?</div>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Control id='FullName' placeholder='Full Name' title='Full Name' onChange={onChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control id='Email' placeholder='Email' title='Email' onChange={onChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control as='textarea' id='Enquiry' placeholder='Message' onChange={onChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='g-recaptcha' />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <LoaderButton
                                    className='submit btn-success btn-lg'
                                    disabled={!validate()}
                                    onClick={onSubmit}
                                >
                        Request Info
                                </LoaderButton>
                            </Col>
                        </Row>
                        <Row>
                            {failure ?
                                <Card
                                    bg='danger'
                                    text='white'
                                    className='mb-2'
                                >
                                    <Card.Body>
                                        <Card.Title>Error Sending Feedback</Card.Title>
                                        <Card.Text>
                                            {failure}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                :
                                ''
                            }
                        </Row>
                    </Form>
                </>
    );
}
