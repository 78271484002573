import React from 'react';
import { Row, Col } from 'react-bootstrap';

/**
 * Global thankyou page
 * @returns {HTMLElement} html of the global thankyou
 */
export default function Thankyou() {

    return (
        <Row>
            <Col>
                <div className='jumbotron'>
                    <h2>Thank You!</h2>
                    <p>
                        Thank you for you question or feedback. We will contact you as
                        soon as possible. In the meantime, take a look at our&nbsp;
                        <a href='/features.html'>features</a> page.
                    </p>
                </div>
            </Col>
        </Row>
    );
}